import React from "react"
import { graphql , useStaticQuery } from "gatsby"
import Img from '../images/not-found.svg'

function NotFound () {



    return (

      <>
      <div className="not-found-wrapper">
        <img src={Img} className="not-found-img" />
      </div>
      </>
    )

}

export default NotFound
