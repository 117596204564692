import React from "react"
import { graphql , useStaticQuery } from "gatsby"
import Img from 'gatsby-image'

//import Layout from "../components/layout"
import NotFound from "../components/404"

function NotFoundPage () {
    return (
       <NotFound/>
    )
}

export default NotFoundPage
